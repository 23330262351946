import React from "react";
import { Highlight } from "prism-react-renderer";

const CodeBlock = ({ language = "jsx", code = `hello, world` }) => (
  <Highlight code={code.trimStart().trimEnd()} language={language}>
    {({ className, style, tokens, getLineProps, getTokenProps }) => (
      <pre className={className} style={{ ...style }}>
        {tokens.map((line, index) => {
          const lineProps = getLineProps({ line, key: index });
          return (
            <div key={index} {...lineProps}>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({ token, key })} />
              ))}
            </div>
          );
        })}
      </pre>
    )}
  </Highlight>
);

export default CodeBlock;
