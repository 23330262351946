import * as React from "react";
import { graphql } from "gatsby";

import SingleArticleSection from "./SingleArticleSection";

const SingleArticle = ({ data }) => {
  const { body } = data.mdx;
  const { title } = data.mdx.frontmatter;

  return (
    <div className="bg-white">
      <SingleArticleSection title={title} body={body} />
    </div>
  );
};

export default SingleArticle;

export const query = graphql`
  query Article($id: String) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
      }
    }
  }
`;
