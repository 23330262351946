import React from "react";
import { Helmet } from "react-helmet";

const PageTitle = ({ pageTitle }) => (
  <Helmet>
    <html lang={"en"} />
    <title>{pageTitle}</title>
  </Helmet>
);

export default PageTitle;
