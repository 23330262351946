import * as React from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import CodeBlock from "./CodeBlock";
import PreCode from "./PreCode";

const Paragraph = (props) => <p className="mb-4" {...props} />;
const H1 = ({ children, ...props }) => (
  <h1
    className="text-gray-800"
    {...props}
  >
    {children}
  </h1>
);
const H2 = ({ children, ...props }) => (
  <h2
    className="text-gray-800"
    {...props}
  >
    {children}
  </h2>
);
const H3 = ({ children, ...props }) => (
  <h3
    className="text-gray-800"
    {...props}
  >
    {children}
  </h3>
);
const Table = ({ children, ...props }) => (
  <div className="overflow-x-auto">
    <table {...props}>{children}</table>
  </div>
);
// Styled components
const ArticleImg = ({ children, src, position, size }) => {
  const cl = {
    small: "h-auto max-w-[25%]",
    medium: "h-auto max-w-[50%]",
    big: "h-full w-full",
  }[size || "big"];

  const isRightPos = position === "right";
  const isBigSize = size === "big";

  return (
    <Paragraph
      className={`mb-4 gap-5 ${isBigSize ? "inline-block" : "inline-flex"} ${
        isRightPos && "flex-row-reverse"
      }`}
    >
      <GatsbyImage
        image={getImage(src)}
        className={`h-full w-full ${cl} object-cover`}
        alt=""
      />
      {children && (
        <Paragraph className={`inline-block ${isBigSize && "mt-5"}`}>
          {children}
        </Paragraph>
      )}
    </Paragraph>
  );
};

export const styledElements = {
  h1: H1,
  h2: H2,
  h3: H3,
  table: Table,
  p: Paragraph,
  pre: PreCode,
};
export const styledComponents = {
  ArticleImg,
  Paragraph,
  H3,
  CodeBlock,
};

const components = { ...styledElements, ...styledComponents };

export default components;
